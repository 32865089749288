namespace UEventTarget {
  export const wrap = <Target extends EventTarget>(eventTarget: Target) => {
    const events = {
      // TODO: Fix type
      on<
        EventType extends keyof HTMLElementEventMap,
        // EventType extends any extends typeof eventTarget.addEventListener<
        //   infer T extends keyof HTMLElementEventMap
        // >
        //   ? T
        //   : never,
      >(
        type: EventType,
        listener: (this: HTMLElement, e: HTMLElementEventMap[EventType]) => any,
        // listener: Parameters<typeof eventTarget.addEventListener<EventType>>[1],
      ) {
        // @ts-expect-error
        eventTarget.addEventListener(type, listener);
        return {
          off() {
            // @ts-expect-error
            eventTarget.removeEventListener(type, listener);
          },
        };
      },
      once<
        EventType extends keyof HTMLElementEventMap,
        // EventType extends any extends typeof eventTarget.addEventListener<
        //   infer T extends keyof HTMLElementEventMap
        // >
        //   ? T
        //   : never,
      >(
        type: EventType,
        listener: (this: HTMLElement, e: HTMLElementEventMap[EventType]) => any,
        // listener: Parameters<typeof eventTarget.addEventListener<EventType>>[1],
      ) {
        const withOff: typeof listener = (e: any) => {
          res.off();
          // @ts-expect-error
          return listener.bind(eventTarget)(e);
        };

        const res = events.on(type, withOff);
        return res;
      },
    };
    return events;
  };
}

export default UEventTarget;
